<template>
   <main id="content">
      <div class="container">
        <div class="row">
            <div class="col-12 mt-05 mb-5">
            <!-- big grid 1 -->
            <div class="row featured-1">
              <!--Start left cover-->
              <div class="col-md-6 pb-05 pt-05 pe-md-05">
                <div class="card card-full text-light overflow zoom">
                  <div class="height-ratio image-wrapper" v-for="publish in topsection.slice(0,1)" :key="publish.id">
                    <!--thumbnail-->
                    <a @click="getFeed(publish)" class="pointer">
                      <img class="img-harry" v-lazy="publish.open_graph_image"  alt="Image description">
                    </a>
                    <div class="position-absolute p-3 b-0 w-100 bg-lg-shadow content-container-cat">
                      <!--title-->
                      <a @click="getFeed(publish)" class="pointer">
                        <h3 class="h1-sm h2-md display-4-lg fw-500 text-white arabic-kufi text-harry">{{ publish.post_title.substring(0,70) }}</h3>
                      </a>
                      <!-- author and date -->
                      <div class="news-meta arabic-kufi">
                        <!-- <span class="news-author">بواسطة <a class="text-white fw-bold" >تاق برس</a></span> -->
                        <time class="news-date" datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--End left cover-->
              <!--Start box news-->
              <div class="col-md-6 pt-05 ps-md-05">
                <div class="row newsbox">
                  <!--news box-->
                  <article class="col-6" v-for="publish in topsection.slice(1,5)" :key="publish.id">
                    <div class="card card-full text-white overflow zoom">
                      <div class="height-ratio image-wrapper">
                        <!--thumbnail-->
                        <a @click="getFeed(publish)" class="pointer">
                          <img class="img-harry1" v-lazy="publish.open_graph_image"  alt="Image description">
                        </a>
                        <!-- category & title -->
                        <a class="p-1 badge bg-primary text-white top-right" >{{ publish.name }}</a>
                        <div class="position-absolute px-3 pb-3 pt-0 b-0 w-100 bg-shadow arabic-kufi">
                          
                          <a @click="getFeed(publish)">
                            <h3 class="h6 h4-sm h6-md h5-lg text-white my-1 arabic-kufi pointer text-harry-cat">{{ publish.post_title.substring(0,45) }}</h3>
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--news box-->
                </div>
              </div>
              <!--End box news-->
            </div>
            <!-- end big grid 1 -->
          </div>
          <!--start left column-->
          <div class="col-md-8">
            <!--Block start-->
            <div class="block-area">
              <!-- block title -->
              <div class="block-title-6 ">
                <h4 class="h5 border-primary">
                  <span class="bg-primary text-white arabic-kufi">السياسية</span>
                </h4>
              </div>
              <!--block content-->
              <div class="row">
                <!--post left start-->
                <div class="col-lg-6">
                  <article class="card card-full hover-a mb-4" v-for="publish in topsection.slice(5,6)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                      </a>
                    </div>
                    <div class="card-body">
                      <!-- title -->
                      <h2 class="card-title h1-sm h3-lg arabic-kufi">
                        <a @click="getFeed(publish)">{{ publish.post_title.substring(0,100) }}</a>
                      </h2>
                      <!-- author, date and comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 arabic-kufi pointer">
                          <a class="fw-bold" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                  </article>
                </div>
                <!--end post left-->
                <!--post right list start-->
                <div class="col-lg-6">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in topsection.slice(6,9)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_115-80 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                            <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-lg arabic-kufi pointer">
                            <a @click="getFeed(publish)">{{ publish.post_title.substring(0,50) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--post list-->
                </div>
                <!--end post right list-->
              </div>
              <!-- end block content -->
            </div>
            <!-- End block -->

        

            <!--Block start-->
            <div class="block-area">
              <!--block title-->
              <div class="block-title-6">
                <h4 class="h5 border-primary">
                  <!-- <span class="bg-primary text-white">Travel</span> -->
                </h4>
              </div>
              <!-- block content -->
              <div class="row">
                <!--post list-->
                <article class="col-sm-6 col-md-12 col-lg-6" v-for="publish in topsection.slice(9,13)" :key="publish.id">
                  <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                      </a>
                    </div>
                    <div class="card-body">
                      <!-- title -->
                      <h2 class="card-title h1-sm h3-lg arabic-kufi pointer">
                        <a @click="getFeed(publish)">{{ publish.post_title.substring(0,70) }}</a>
                      </h2>
                      <!-- author, date and comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 arabic-kufi">
                          <a class="fw-bold" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                </article>
                <div class="gap-05"></div>
              </div>
              <!-- end block content -->
            </div>
            <!--End Block-->

            <!-- block start -->
            <div class="block-area">
              <!-- block title -->
              <div class="block-title-6">
                <h4 class="h5 border-primary">
                  <span class="bg-primary text-white arabic-kufi">مزيد من الاخبار السياسية</span>
                </h4>
              </div>
              <!-- block content -->
              <div class="border-bottom-last-0 first-pt-0">
                <!--post start-->
                <article class="card card-full hover-a py-4" v-for="publish in topsection.slice(13,18)" :key="publish.id">
                  <div class="row">
                    <div class="col-sm-6 col-md-12 col-lg-6">
                      <!--thumbnail-->
                      <div class="ratio_360-202 image-wrapper">
                        <a @click="getFeed(publish)" class="pointer">
                          <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                        </a>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-12 col-lg-6">
                      <div class="card-body pt-3 pt-sm-0 pt-md-3 pt-lg-0">
                        <!--title-->
                        <h3 class="card-title h2 h3-sm h2-md arabic-kufi pointer">
                          <a @click="getFeed(publish)">{{ publish.post_title.substring(0,150) }}</a>
                        </h3>
                        <!--author and date-->
                        <div class="card-text mb-2 text-muted small">
                          <span class="d-none d-sm-inline me-1 arabic-kufi">
                            <a class="fw-bold" href="#">تاق برس</a>
                          </span>
                          <time datetime="2019-10-21">{{ publish.post_date.substring(0,10) }}</time>
                        </div>
                        <!--description-->
                      </div>
                    </div>
                  </div>
                </article>
                <!--post start-->
              </div>
              <!-- end block content -->
            </div>
            <!--end block-->

            <!--Pagination-->
            <div class="clearfix my-4">
              <nav class="float-start" aria-label="Page navigation example">
                <!--page number-->
                <!-- <ul class="pagination">
                  <li class="page-item active"><span class="page-link">1</span></li>
                  <li class="page-item"><a class="page-link" href="../category/category.html">2</a></li>
                  <li class="page-item"><a class="page-link" href="../category/category.html">3</a></li>
                  <li class="page-item"><a class="page-link" href="../category/category.html">4</a></li>
                  <li class="page-item"><span class="page-link disabled">....</span></li>
                  <li class="page-item"><a class="page-link" href="../category/category.html">12</a></li>
                  <li class="page-item">
                    <a class="page-link" href="../category/category.html" aria-label="Next" title="Next page">
                      <span aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" fill="currentColor" viewBox="0 0 512 512"><polyline points="184 112 328 256 184 400" style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px"/></svg>
                      </span>
                      <span class="visually-hidden">Next</span>
                    </a>
                  </li>
                </ul> -->
                <!--end page number-->
              </nav>
              <!-- <span class="py-2 float-end">Page 1 of 12</span> -->
            </div>
            <!--end pagination-->
          </div>
          <!--end left column-->

          <!--start right column-->
          <aside class="col-md-4 end-sidebar-lg">
            <div class="sticky">
              <!--Block start-->
              <aside class="widget">
                <!--block title-->
                <div class="block-title-9">
                  <h4 class="h5">
                    <span class="border-primary bg-secondary text-white arabic-kufi">اخر الاخبار</span>
                  </h4>
                </div>
                <!-- block content -->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in lastnews.slice(0,4)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                            <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md arabic-kufi pointer">
                            <a @click="getFeed(publish)">{{ publish.post_title.substring(0,50) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-16">{{ publish.post_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--post list-->
                </div>
                <!--end block content-->
                <div class="gap-0"></div>
              </aside>
              <!-- end block -->

              <!--post number list-->
              <aside class="widget">
                <!--block title-->
                <div class="block-title-9">
                  <h4 class="h5">
                    <span class="border-primary bg-secondary text-white"></span>
                  </h4>
                </div>
                <!-- Block content -->
                <ul class="post-number list-unstyled border-bottom-last-0 rounded mb-3">
                  <li class="hover-a" v-for="publish in lastnews.slice(4,8)" :key="publish.id">
                    <a class="h5 h6-md h5-lg arabic-kufi pointer" @click="getFeed(publish)">{{ publish.post_title.substring(0,50) }}</a>
                  </li>
                </ul>
                <!-- end block content -->
                <div class="gap-05"></div>
              </aside>
              <!-- end block -->

              <!--Block start-->
              <aside class="widget">
                <!--block title-->
                <div class="block-title-9">
                  <h4 class="h5">
                    <span class="border-primary bg-secondary text-white arabic-kufi">السياسية</span>
                  </h4>
                </div>
                <!-- block content -->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4" v-for="publish in topsection.slice(18,22)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                            <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h5-sm h6-md arabic-kufi pointer">
                            <a @click="getFeed(publish)">{{ publish.post_title.substring(0,50) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-16">{{ publish.post_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--post list-->
                  
                </div>
                <!--end block content-->
                <div class="gap-0"></div>
              </aside>
              <!-- end block -->

              <!-- latest post -->
              <aside class="widget">
                <!--block title-->
                <div class="block-title-9">
                  <h4 class="h5">
                    <span class="border-primary bg-secondary text-white"></span>
                  </h4>
                </div>
                <!--post big start-->
                <div class="big-post">
                  <article class="card card-full hover-a mb-4" v-for="publish in topsection.slice(22,23)" :key="publish.id">
                    <!--thumbnail-->
                    <div class="ratio_360-202 image-wrapper">
                      <a @click="getFeed(publish)" class="pointer">
                        <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                      </a>
                    </div>
                    <div class="card-body">
                      <!--title-->
                      <h2 class="card-title h3 arabic-kufi pointer">
                        <a @click="getFeed(publish)">{{ publish.post_title.substring(0,50) }}</a>
                      </h2>
                      <!-- author & date -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1 arabic-kufi">
                          <a class="fw-bold" href="#">تاق برس</a>
                        </span>
                        <time datetime="2019-10-22">{{ publish.post_date.substring(0,10) }}</time>
                      </div>
                      <!--description-->
                    </div>
                  </article>
                </div>
                <!--end post big-->
                <!--post small start-->
                <div class="small-post">
                  <!--post list-->
                  <article class="card card-full hover-a mb-4"  v-for="publish in topsection.slice(23,27)" :key="publish.id">
                    <div class="row">
                      <!--thumbnail-->
                      <div class="col-3 col-md-4 pe-2 pe-md-0">
                        <div class="ratio_110-77 image-wrapper">
                          <a @click="getFeed(publish)" class="pointer">
                            <img class="img-fluid" v-lazy="publish.open_graph_image" alt="Image description">
                          </a>
                        </div>
                      </div>
                      <!-- title & date -->
                      <div class="col-9 col-md-8">
                        <div class="card-body pt-0">
                          <h3 class="card-title h6 h4-sm h6-md arabic-kufi pointer">
                            <a @click="getFeed(publish)">{{ publish.post_title.substring(0,50) }}</a>
                          </h3>
                          <div class="card-text small text-muted">
                            <time datetime="2019-10-16">{{ publish.post_date.substring(0,10) }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                  <!--post list-->
                </div>
                <!--end post small-->
                <div class="gap-0"></div>
              </aside>
              <!-- end latest post -->
            </div>
          </aside>
          <!--end right column-->
        </div>
      </div>
  </main>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next';
import { useMeta } from 'vue-meta'
export default {
   name: 'Policys',
   async setup() {
    useMeta({ title: 'سياسية' })
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const topsection = ref([]);
      const lastnews = ref([]);
      try {
        await HTTP.get(`getPostbyCateogry.php?getPostbyCateogry&term_id=2&LIMIT=30&OFFSET=0`).then((res) => {
          topsection.value = res.data.getPostbyCateogry; 
        });
      } catch (err) {
        console.log(err);
      }
      try {
        await HTTP.get(`getPost.php?getPost&LIMIT=8&OFFSET=0`).then((res) => {
          lastnews.value = res.data.getPost; 
        });
      } catch (err) {
        console.log(err);
      }
      const getFeed = (publish) => {
          cookie.setCookie('article_id', publish.id)
          router.push({ name: "ReadArticle", params: { id: publish.id } });
        };
        return {
           topsection,
           lastnews,
           getFeed
        };
   },
}
</script>

<style scoped>
.arabic-kufi {
  font-family: 'Zain_Regular', serif;
}
.pointer {
  cursor: pointer;
}



</style>